<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card
          v-if="title"
          :title="`${title}`"
        >
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSV">
                CSV
              </b-dropdown-item>
              <b-dropdown-item @click="exportExcel">
                MS-Excel
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            ref="myTable"
            :columns="columns"
            :rows="visites"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'status'">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>

              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <!--                  <feather-icon-->
                <!--                    icon="Edit2Icon"-->
                <!--                    class="mr-50"-->
                <!--                  />-->
                <!--                  <span>Edit</span>-->
                <router-link :to="{ name: 'zones-details', params: { id: props.row.id } }">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    Voir
                  </b-button>
                </router-link>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import { API_URL } from '@/helpers/global-scops'
import { array } from '@amcharts/amcharts4/core'
import { required } from '@core/utils/validations/validations'

export default {
  name: 'ListeVisite',
  components: {
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  props: {
    visites: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      // visites: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Produit',
          field: 'nom',
        },
        {
          label: 'Les Entrées de stock',
          field: 'quantity_entree',
        },
        {
          label: 'Les sorties de stock',
          field: 'quantity_sortie',
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    // this.getVisites()
  },
  methods: {
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    // getVisites() {
    //   this.$http.get(`${API_URL}visite-distributeur-par-semaine?distributeur_id=${this.$route.params.id}&semaine=12`)
    //     .then(response => {
    //       // fais une boucle foreach sur response.data
    //
    //       this.visites = response.data[0].produits
    //       this.title = response.data[0].interval
    //     })
    //     .catch(error => {
    //       console.log(error)
    //     })
    // },
  },
}
</script>

<style scoped>

</style>
